import React from 'react'
import { arrayOf, string, shape, bool } from 'prop-types'
import { useThemeContext } from '@/component/Context/ThemeContext'

import BBFCSubNavigation from './component/BBFC/BBFCSubNavigation'
import CBBFCSubNavigation from './component/CBBFC/CBBFCSubNavigation'

const SubNavigation = ({
  links,
  title,
  isTeal,
  isOrange,
  hasLine,
  hasPointer
}) => {
  const theme = useThemeContext()
  const componentMap = {
    bbfc: <BBFCSubNavigation links={links} title={title} />,
    cbbfc: (
      <CBBFCSubNavigation
        links={links}
        title={title}
        isTeal={isTeal}
        isOrange={isOrange}
        hasLine={hasLine}
        hasPointer={hasPointer}
      />
    )
  }

  return componentMap[theme]
}

SubNavigation.propTypes = {
  links: arrayOf(shape({ title: string, url: string, rating: string })),
  title: string,
  isTeal: bool,
  isOrange: bool,
  hasLine: bool,
  hasPointer: bool
}

export default SubNavigation
