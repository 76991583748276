import { arrayOf, string, shape, object } from 'prop-types'

export default {
  links: arrayOf(
    shape({
      name: string.isRequired,
      as: string,
      rating: string,
      to: string.isRequired
    })
  ),
  title: string,
  style: object
}
