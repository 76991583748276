import React from 'react'
import subNavigationPropTypes from '@/shape/sub-navigation'

import classNames from 'classnames'
import { useDarkContext } from '@/component/Context/DarkContext'

import SmartLink from '@/component/Primitive/SmartLink'
import ShrinkWrap from '@/component/Primitive/ShrinkWrap'
import Type from '@/component/Primitive/Type'
import Icon from '@/component/Primitive/Icon'
import AgeRating from '@/component/Primitive/AgeRating'
import Surround from '@/component/Primitive/Surround'

const SubNavigation = ({ links, title, style, isTeal, isOrange }) => {
  const isDark = useDarkContext()
  return (
    <div
      className={classNames(
        style.SubNavigation,
        isDark && style.isDark,
        !isDark && isTeal && style.isTeal,
        !isDark && isOrange && style.isOrange
      )}
    >
      <Type as="h3" size="display0" className={style.Title}>
        {title}
      </Type>
      <Surround rounded>
        <ul className={style.List}>
          {links &&
            links.map((item, i) => (
              <li className={style.ListItem} key={`Link-${item.name}-${i}`}>
                <SmartLink as={item.as} to={item.to}>
                  <ShrinkWrap vAlign="middle">
                    {item.rating && (
                      <ShrinkWrap.Item shrink>
                        <AgeRating
                          className={style.Rating}
                          rating={item.rating}
                          size={4}
                        />
                      </ShrinkWrap.Item>
                    )}
                    <ShrinkWrap.Item>
                      <Type as="h4" size="base" bold={!item.rating}>
                        {item.name}
                      </Type>
                    </ShrinkWrap.Item>
                  </ShrinkWrap>
                </SmartLink>
                <Icon
                  type="chevron-right"
                  height={40}
                  className={style.ChevronRight}
                  a11yText="Enter"
                />
              </li>
            ))}
        </ul>
      </Surround>
    </div>
  )
}

SubNavigation.propTypes = subNavigationPropTypes

export default SubNavigation
