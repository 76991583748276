import React from 'react'
import { bool } from 'prop-types'
import classNames from 'classnames'
import SubNavigation from '../SubNavigation'
import Icon from '@/component/Primitive/Icon'

import styles from './CBBFCSubNavigation.module.scss'
import { useDarkContext } from '@/component/Context/DarkContext'

const CBBFCSubNavigation = (props) => {
  const isDark = useDarkContext()

  return (
    <div className={styles.SubNavigationWrapper}>
      {props.hasPointer && (
        <>
          <div className={styles.Triangles}>
            <Icon type="triangles" />
          </div>
          <Icon
            type="triangle"
            className={classNames(styles.Pointer, isDark && styles.isDark)}
            a11yText="Pointer"
          />
        </>
      )}
      {props.hasLine && <Icon type="wavy-line" className={styles.Line} />}
      <SubNavigation {...props} style={styles} />
    </div>
  )
}

CBBFCSubNavigation.propTypes = {
  hasLine: bool,
  hasPointer: bool
}

export default CBBFCSubNavigation
