import React from 'react'
import classNames from 'classnames'
import { bool, node, oneOf, oneOfType, string } from 'prop-types'
// import sassKeysToArray from '@/lib/sass-keys-to-array'

import styles from './GridWrapper.module.scss'

// const gutters = sassKeysToArray(styles.gutters)
const gutters = ['half', 'default', 'large', 'small']

const GridWrapper = ({
  children,
  gutter,
  reverse,
  flex,
  mobileFlexReverse,
  vAlign,
  mobileCarousel,
  className
}) => (
  <div
    className={classNames(
      // styles.GridWrapper,
      flex && styles.flex,
      mobileFlexReverse && styles['mobile-reversed-flex'],
      gutter && styles[`gutter-${gutter}`],
      reverse &&
        styles[`reverse${typeof reverse === 'string' ? '-' + reverse : ''}`],
      vAlign && styles[`valign-${vAlign}`],
      mobileCarousel && styles['mobile-carousel'],
      className
    )}
  >
    {children}
  </div>
)

GridWrapper.propTypes = {
  children: node.isRequired,
  flex: bool,
  mobileFlexReverse: bool,
  mobileCarousel: bool,
  gutter: oneOf(gutters),
  reverse: oneOfType([bool, string]),
  vAlign: string,
  className: string
}

export default GridWrapper
