import React from 'react'
import classNames from 'classnames'
import {
  arrayOf,
  bool,
  node,
  number,
  object,
  oneOf,
  oneOfType,
  string
} from 'prop-types'

import styles from './GridItem.module.scss'

const columns = ['m', 't', 'tl', 'd', 'dm', 'dl']
const trailingGaps = ['mobile', 'default']

const getWidthClasses = (widths) => {
  if (Array.isArray(widths)) {
    return widths.map(
      (width, i) => styles[`${columns[i]}-${Math.round(width * 100)}`]
    )
  }
  return Object.entries(widths).map(
    (width) =>
      columns.includes(width[0]) &&
      styles[`${width[0]}-${Math.round(width[1] * 100)}`]
  )
}

const GridItem = ({
  children,
  width,
  grow,
  trailingGap,
  mobileCarousel,
  className
}) => (
  <div
    className={classNames(
      styles.GridItem,
      grow && styles.grow,
      width && getWidthClasses(width),
      trailingGap && styles[`trailingGap-${trailingGap}`],
      mobileCarousel && styles['mobile-carousel'],
      className
    )}
  >
    {children}
  </div>
)

GridItem.displayName = 'GridItem'

GridItem.propTypes = {
  children: node.isRequired,
  trailingGap: oneOf(trailingGaps),
  grow: bool,
  mobileCarousel: bool,
  width: oneOfType([arrayOf(number), object]),
  className: string
}

export default GridItem
