import React from 'react'
import { oneOf, node } from 'prop-types'

const ThemeContext = React.createContext()

const ThemeContextProvider = ({ children, theme }) => {
  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
}

ThemeContextProvider.defaultProps = {
  theme: 'bbfc'
}

ThemeContextProvider.propTypes = {
  theme: oneOf(['bbfc', 'cbbfc']).isRequired,
  children: node.isRequired
}

const ThemeContextConsumer = ThemeContext.Consumer
const useThemeContext = () => React.useContext(ThemeContext)

export {
  ThemeContext,
  ThemeContextProvider,
  ThemeContextConsumer,
  useThemeContext
}
